import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import logo from '@assets/logo.svg';
import { fetchIssues } from '@queries/Issues';
import { Badge } from '@layouts';

const formatTime = (val) =>
  `${Math.floor(val / 3600 / 8)}d ${Math.floor(
    (val / 3600 / 8 - Math.floor(val / 3600 / 8)) * 8
  )}h ${
    ((val / 3600 / 8 - Math.floor(val / 3600 / 8)) * 8 -
      Math.floor((val / 3600 / 8 - Math.floor(val / 3600 / 8)) * 8)) *
    60
  }m`;

function Home() {
  const [searchParams] = useSearchParams();
  const [issues, setIssues] = useState([]);
  const navigate = useNavigate();

  const clientId = searchParams.get('client_id');

  // Fetch live issues from Gitlab
  useQuery(['issues', clientId], () => fetchIssues(clientId), {
    onSuccess: (data) => {
      console.log(data);
      setIssues(
        data?.data?.map((o) => ({
          name: o?.title,
          state: o?.state,
          labels: o?.labels,
          milestone: o?.milestone?.title,
          charge: o?.time_stats?.human_total_time_spent,
          chargeRaw: o?.time_stats?.total_time_spent,
          milestoneStartDate: o?.milestone?.start_date
        }))
      );
    }
  });

  // Redirect if url badly formatted
  useEffect(() => {
    if (!clientId) {
      navigate('/404');
    }
  }, [clientId]);

  return (
    <div className="p-4 sm:p-6 lg:p-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <img className="h-12 w-auto mb-8" src={logo} alt="" />
          <h1 className="text-xl font-semibold text-gray-900">Suivi du portefeuille projet</h1>
          <p className="mt-2 text-sm text-gray-700">
            Tableau dynamique d&apos;avancement et de suivi du portefeuille de projets au cours du
            temps.
          </p>
        </div>
      </div>
      <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0">
                Description de la tâche
              </th>
              <th
                scope="col"
                className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                Milestone
              </th>
              <th
                scope="col"
                className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                Statut
              </th>
              <th
                scope="col"
                className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                Labels
              </th>
              <th
                scope="col"
                className="py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                Charge
              </th>
            </tr>
          </thead>
          <tbody>
            {_.orderBy(issues, ['milestoneStartDate', 'state'], ['desc', 'desc']).map((issue) => (
              <tr key={issue.id} className="border-b border-gray-200">
                <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
                  <div className="hidden text-gray-500 sm:table-cell">{issue.name}</div>
                  <div className="text-gray-900 sm:hidden">{issue.name}</div>
                  <div className="mt-0.5 text-gray-500 sm:hidden">
                    {issue.milestone} -{' '}
                    <Badge
                      className={
                        {
                          undefined: 'bg-green-100 text-green-800',
                          Development: 'bg-sky-100 text-sky-800',
                          'Code Review': 'bg-sky-100 text-sky-800',
                          'Functional Review': 'bg-sky-100 text-sky-800',
                          true: 'bg-blue-100 text-blue-800'
                        }[
                          issue?.state === 'closed' ||
                            issue?.labels
                              ?.filter((o) => o?.split('::')?.at(0) === 'state')?.[0]
                              ?.split('::')
                              ?.at(-1)
                        ]
                      }>
                      {issue?.state === 'closed'
                        ? 'Close'
                        : issue?.labels
                            ?.filter((o) => o?.split('::')?.at(0) === 'state')?.[0]
                            ?.split('::')
                            ?.at(-1) || 'Open'}
                    </Badge>
                  </div>
                </td>
                <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                  {issue.milestone}
                </td>
                <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                  <Badge
                    className={
                      {
                        undefined: 'bg-green-100 text-green-800',
                        Development: 'bg-sky-100 text-sky-800',
                        'Code Review': 'bg-sky-100 text-sky-800',
                        'Functional Review': 'bg-sky-100 text-sky-800',
                        true: 'bg-blue-100 text-blue-800'
                      }[
                        issue?.state === 'closed' ||
                          issue?.labels
                            ?.filter((o) => o?.split('::')?.at(0) === 'state')?.[0]
                            ?.split('::')
                            ?.at(-1)
                      ]
                    }>
                    {issue?.state === 'closed'
                      ? 'Close'
                      : issue?.labels
                          ?.filter((o) => o?.split('::')?.at(0) === 'state')?.[0]
                          ?.split('::')
                          ?.at(-1) || 'Open'}
                  </Badge>
                </td>
                <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell leading-loose	">
                  {issue?.labels
                    ?.filter((o) => o?.split('::')?.at(0) !== 'state')
                    ?.map((o, index) => (
                      <Badge
                        key={index}
                        className={
                          {
                            Analysis: 'ml-1 bg-yellow-100 text-yellow-800',
                            false: 'ml-1 bg-[#f3f4f6] text-[#1f2937]'
                          }[o.split('::').length > 1 && o.split('::').at(0)]
                        }>
                        {o?.split('::')?.at(-1)}
                      </Badge>
                    ))}
                </td>
                <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                  {issue.charge}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th
                scope="row"
                colSpan={4}
                className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0">
                Tickets ouverts
              </th>
              <th
                scope="row"
                className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
                Tickets ouverts
              </th>
              <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {issues?.filter((o) => o?.state === 'opened')?.length}
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                colSpan={4}
                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0">
                Tickets fermés
              </th>
              <th
                scope="row"
                className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">
                Tickets fermés
              </th>
              <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {issues?.filter((o) => o?.state === 'closed')?.length}
              </td>
            </tr>
            <tr>
              <th
                scope="row"
                colSpan={4}
                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0">
                Total
              </th>
              <th
                scope="row"
                className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
                Total
              </th>
              <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                {issues?.length}
              </td>
            </tr>
            {
              <tr>
                <th
                  scope="row"
                  colSpan={4}
                  className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0">
                  Charge totale
                </th>
                <th
                  scope="row"
                  className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
                  Charge totale
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                  {formatTime(issues?.map((o) => o?.chargeRaw)?.reduce((a, b) => a + b, 0))}
                </td>
              </tr>
            }
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default Home;
