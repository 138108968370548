import logo from '@assets/logo.svg';

function NotFound() {
  return (
    <div className="flex min-h-screen flex-col bg-white pt-16 pb-12">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <img className="h-12 w-auto" src={logo} alt="" />
          </a>
        </div>
        <div className="py-4">
          <div className="text-center">
            <p className="text-base font-semibold text-teal-600">404</p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Page not found.
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Sorry, we couldn’t find the page you’re looking for.
              <br />
              Please make sure to click on the link you have received by mail.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}

export default NotFound;
