function Badge(props) {
  const { children = null, className = '', ...rest } = props;

  return (
    <span
      className={`inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800 ${className}`}
      {...rest}>
      {children}
    </span>
  );
}

export default Badge;
